import React from 'react'
import qs from 'qs'
import { Spinner } from 'baseui/spinner'
import { Block } from 'baseui/block'
import { Label1 } from 'baseui/typography'
import { getProfile } from '../actions/auth'
import withUser from '../hoc/withUser'
import { navigate } from 'gatsby'

const Login = ({ location, userDispatch }) => {
  const windowGlobal = typeof window !== 'undefined' && window
  const { localStorage } = windowGlobal
  const { id_token: tokenId } = qs.parse(location.hash)
  if (tokenId) {
    localStorage.setItem('tokenId', tokenId)
    getProfile(userDispatch).then(() => {
      navigate('/')
    })
  }

  return (
    <Block
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
      height={'100vh'}
    >
      <Spinner
        size={80}
        overrides={{
          ActivePath: {
            style: ({ $theme }) => ({ fill: $theme.colors.primary }),
          },
        }}
      />
      <Label1 style={{ marginTop: 16 }}>กำลังเข้าสู่ระบบ</Label1>
    </Block>
  )
}

export default withUser(Login)
